import { render, staticRenderFns } from "./SuperAdmin.vue?vue&type=template&id=7e00db6c&scoped=true"
import script from "./SuperAdmin.vue?vue&type=script&lang=js"
export * from "./SuperAdmin.vue?vue&type=script&lang=js"
import style0 from "@/views/home/assets/css/Index.css?vue&type=style&index=0&id=7e00db6c&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e00db6c",
  null
  
)

export default component.exports