<template>
  <div>
    <div class="mt-4" v-for="(elem, index) in dashboard" :key="index">
      <div class="card-title">
        <h1 class="section-title">{{ elem.title }}</h1>
      </div>
      <el-row class="card-item">
        <el-col
          :xs="12"
          :sm="12"
          :md="6"
          v-for="(item, key) in elem.items"
          :key="key"
        >
          <!-- 如果此 item 是沒有任何角色限制 -->
          <div class="item">
            <div v-show="!item.show" class="item-cover">
              <div class="cover">
                <span class="cover-icon">
                  <i class="fas fa-lock"></i>
                </span>
                <span>Coming soon</span>
              </div>
            </div>
            <el-card shadow="hover" class="dashboard-card">
              <div class="card-body">
                <!-- 如果此 item 是 Line Login -->
                <div
                  v-if="item.name === 'greet.line_login'"
                  style="height:100%"
                >
                  <a :href="lineLoginAuthUrl" class="card-content">
                    <div style="font-size: 100px">
                      <i class="fab" :class="item.icon"></i>
                    </div>
                    <div>
                      <span>{{ $t(item.name) }}</span>
                    </div>
                  </a>
                </div>
                <!-- 如果此 item 不是 Line Login -->
                <div v-else style="height:100%">
                  <router-link
                    v-if="item.routerName"
                    class="card-content"
                    :to="{
                      name: item.routerName,
                      query: item.query ? item.query : {},
                      params: item.params ? item.params : null
                    }"
                  >
                    <div class="font-icon">
                      <i v-if="item.icon" class="fas" :class="item.icon"></i>
                      <b class="test-color item-name" v-else>{{ $t(item.name) }} </b>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </router-link>
                  <a v-else class="card-content" :href="item.path">
                    <div class="font-icon">
                      <i v-if="item.icon" class="fas" :class="item.icon"></i>
                      <b class="test-color item-name" v-else>{{ $t(item.name) }} </b>
                    </div>
                    <div>
                      <span>
                        {{ $t(item.name) }}
                      </span>
                    </div>
                  </a>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import lineLoginApis from "@/apis/lineLogin";
/*eslint-enable */

import _ from "lodash";

export default {
  metaInfo() {
    return {
      title: "Home - " + this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: [],
  data() {
    return {
      isBindLineLogin: true,
      lineLoginAuthUrl: null,
      dashboard: [
        {
          title: "Management",
          items: [
            {
              // My Classes
              name: "Home.myClasses",
              routerName: "EducatorClassesList",
              auths: ["Educators"],
              icon: "fas fa-chalkboard-teacher",
              show: true
            },
            {
              name: "Users",
              routerName: "AdminViewUsers",
              icon: "fa-users",
              show: true
            },
            {
              name: "Companies",
              routerName: "AdminViewCompanies",
              icon: "fas fa-city",
              show: true
            },
            {
              name: "Test Results",
              routerName: "AllTestResults",
              icon: "fas fa-poll-h",
              show: true
            },
            {
              name: "User Activities",
              routerName: "AdminViewUsersActivities",
              icon: "fas fa-shoe-prints",
              show: true
            },
            {
              name: "Home.Profile",
              routerName: "AdminProfile",
              icon: "fa-user",
              show: true
            }
          ]
        },
        {
          title: "Question Bank",
          items: [
            {
              name: "Home.TestsManagement.Full Tests",
              routerName: "EducatorFullTestsList",
              auths: ["Educators"],
              icon: "fas fa-book",
              show: true
            },
            {
              name: "Home.TestsManagement.Partial Tests",
              routerName: "EducatorModules",
              params: {
                type: "modules"
              },
              auths: ["Educators"],
              icon: "fas fa-book-open",
              show: true
            },
            {
              name: "Home.TestsManagement.Drill Practice",
              routerName: "EducatorModules",
              params: {
                type: "drills"
              },
              auths: ["Educators"],
              icon: "fas fa-chart-bar",
              show: true
            },
            {
              name: "Home.TestsManagement.QuestionsBox",
              routerName: "EducatorQuestions",
              auths: ["Educators"],
              icon: "fas fas fa-tasks",
              show: true
            },
            {
              name: "Home.TestsManagement.Question Labels",
              routerName: "myClasses",
              auths: ["Educators"],
              icon: "fas fa-tags",
              show: false
            }
          ]
        },
        {
          title: "Tests",
          items: [
            {
              // ACT
              name: "greet.ACT",
              routerName: "ACTList",
              auths: ["all"],
              icon: null,
              show: true
            },
            {
              // SAT
              name: "greet.SAT",
              routerName: "SATList",
              auths: ["all"],
              icon: null,
              show: true
            },
            {
              // AP
              name: "greet.AP",
              routerName: "APList",
              auths: ["all", "demo-role"],
              icon: null,
              show: false
            },
            {
              // IELTS
              name: "greet.IELTS",
              routerName: "ToeflList",
              auths: ["all", "demo-role"],
              icon: null,
              show: false
            },
            {
              // TOEFL
              name: "greet.TOEFL",
              routerName: "ToeflList",
              auths: ["all", "demo-role"],
              icon: null,
              show: false
            }
          ]
        },
        {
          title: "Resources",
          items: [
            {
              // Flash Cards
              name: "Home.flashcards",
              routerName: "FlashCards",
              auths: ["all"],
              icon: "fas fa-clipboard-list",
              show: true
            },
            {
              // SchoolsList
              name: "Home.colleges",
              routerName: "SchoolsList",
              query: { rank: 12 },
              auths: ["all"],
              icon: "fa-graduation-cap",
              show: false
            }
          ]
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    isLogin() {
      // TODO: 應該還要檢查 token 是否合法？
      return this.token !== "";
    },
    sat() {
      return this.$route.query.sat;
    },
    role() {
      return this.getRole(this.$route.name);
    }
  },
  watch: {},

  async mounted() {
    // this.dashboard[1].items.push({
    //   // SAT
    //   name: "greet.SAT",
    //   path: `https://sat.ivy-way.com?token=${this.token}`,
    //   auths: ["all", "demo-role"],
    //   icon: "fa-pencil-alt"
    // });
  },

  methods: {
    getRole(routeName) {
      if (routeName.indexOf("students") > -1) {
        return "Students";
      } else if (routeName.indexOf("Educators") > -1) {
        return "Educators";
      } else {
        return "Students";
      }
    },
    hasPrimission({ items }) {
      return items.some(item => {
        return this.permissionAllow(item.auths);
      });
    },
    async fetchLineLoginAuthURL() {
      let url = await this.$router.resolve({
        name: "BindLineLogin"
      });
      let lineLoginAuthUrl = await lineLoginApis.getAuthURL({
        redirect_uri: window.location.origin + url.href
      });
      this.lineLoginAuthUrl = lineLoginAuthUrl.url;
    },
    permissionAllow(allowRoles) {
      // let role = this.profile.all_roles;
      let role = [this.role];
      if (_.includes(role, "demo-role")) {
        if (_.includes(allowRoles, "demo-role")) {
          if (_.includes(allowRoles, "all")) {
            return true;
          }

          return _.intersection(role, allowRoles).length > 1;
        }
        return false;
      }

      if (_.includes(allowRoles, "all")) {
        return true;
      }

      return _.intersection(role, allowRoles).length > 0;
    },
    ...mapActions("user", ["getProfile"])
  }
};
</script>

<style scoped src="@/views/home/assets/css/Index.css"></style>